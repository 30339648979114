@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@200&family=Tangerine&display=swap');

.App {
  text-align: center;
  background-color: #fff5f0;
  width: 80vw;
  margin: auto;
  background-image: url(./paper.png);
}



.center-container {}

.vertical-center {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* background-color: #fff5f0; */
}

.parallax {
  height: 100vh;
  background-image: url(./paper.png);
  background-color: #fff5f0;
}

.content-1,
.content-2 {
  position: absolute;

  font-family: 'Sacramento', cursive;
  font-size: 5rem;
  color: #05078e;

  width: 100%;
  height: 100%;

}

.tipis {
  background-image: url('./tipis4.png');
  background-size: 30%;
  background-repeat: no-repeat;
  background-position: 80% 80%;
  background-attachment: fixed;
  height: 100%;
  position: absolute;
  /* opacity: 0.35; */
  width: 100%;
}

.stw {
  background-image: url('./stwilfreds_outside_cropped.jpeg');
  background-size: auto;
  background-repeat: no-repeat;
  background-position: 30% 30%;
  background-attachment: fixed;
  height: 100%;
  position: absolute;
  opacity: 0.35;
  width: 100%;
}